html,
body {
  height: 100%;
  margin: 0;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  display: none;
}

/* Typography */
@font-face {
  font-family: Poppins-Bold;
  src: url('/fonts/Poppins-Bold.ttf');
  font-display: swap;
}

@font-face {
  font-family: Poppins-Semibold;
  src: url('/fonts/Poppins-SemiBold.ttf');
  font-display: swap;
}

@font-face {
  font-family: Poppins-Medium;
  src: url('/fonts/Poppins-Medium.ttf');
  font-display: swap;
}

@font-face {
  font-family: Poppins-Regular;
  src: url('/fonts/Poppins-Regular.ttf');
  font-display: swap;
}

@font-face {
  font-family: Lexend-Regular;
  src: url('/fonts/Lexend-Regular.ttf');
  font-display: swap;
}

@font-face {
  font-family: Lexend-Semibold;
  src: url('/fonts/Lexend-SemiBold.ttf');
  font-display: swap;
}

:root {
  --primary: #ff9906;
  --white: #ffffff;
  --black: #202020;
  --bgColor: #f9f9f9;
  --error: #d86565;
  --gray: #a8a8a8;
  --lightGray: #d9d9d9;
  --success: #66ad72;
}

/* Typography */
.poppins-bold {
  font-family: Poppins-Bold;
  font-size: 1.2rem;
}
.poppins-semibold {
  font-family: Poppins-Semibold;
  font-size: 0.9rem;
}
.poppins-medium {
  font-family: Poppins-Medium;
  font-size: 0.9rem;
}
.poppins-regular {
  font-family: Poppins-Regular;
}
.lexend-regular {
  font-family: Lexend-Regular;
  font-size: 0.8rem;
}
.lexend-semibold {
  font-family: Lexend-Semibold;
  font-size: 1.3rem;
}
.font-xs {
  font-size: 0.7rem;
}
.font-sm {
  font-size: 0.8rem;
}
.font-md {
  font-size: 0.9rem;
}
.font-lg {
  font-size: 1rem;
}
.font-xl {
  font-size: 1.2rem;
}
.font-2xl {
  font-size: 1.3rem;
}

.inputLabelStyle {
  padding: 5 8 0 8;
  font-family: Poppins-Semibold;
  font-size: 0.9rem;
}

.modalText {
  font-family: Poppins-Medium;
  font-size: 0.8rem;
}

.text-active {
  color: var(--primary);
}

.text-inactive {
  color: var(--lightGray);
}

/* Border Styles */
.grayBottomBorder {
  border-bottom: 1px solid rgba(183, 183, 183, 0.3);
}
.lightGrayBottomBorder {
  border-bottom: 1px solid rgba(217, 217, 217, 0.3);
}
.grayTopBorder {
  border-top: 1px solid rgba(183, 183, 183, 0.3);
}
.grayRoundBorder {
  border: 1px solid var(--gray);
  border-radius: 20px;
}
.orangeRoundBorder {
  border: 1px solid var(--primary);
  border-radius: 20px;
}
.grayBoxBorder {
  margin-top: 6;
  border: 1px solid var(--gray);
  border-radius: 10px;
}
.orangeBoxBorder {
  border: 1px solid var(--primary);
  border-radius: 8px;
  margin-top: 2px;
}

.grayUnderlineInput {
  border-bottom: 1px solid var(--gray);
}

/* Shadow Styles */
.defaultShadow {
  box-shadow: 0 4px 3px 0 rgba(0, 0, 0, 0.03);
}
.orangeShadow {
  box-shadow: 0 4px 3px 0 rgba(255, 153, 6, 0.1);
}
.noShadow {
  box-shadow: none;
}
.navbarShadow {
  box-shadow: 0 -3px 3px 0 rgb(0 0 0 / 0.03);
}

/* Spacing Styles */
.marginHorizontal {
  margin-left: 26px;
  margin-right: 26px;
}
.paddingHorizontal {
  padding-left: 26px;
  padding-right: 26px;
}
.paddingHorizontalSmall {
  padding-left: 20px;
  padding-right: 20px;
}
.mainContainer {
  margin-top: '7%';
  padding-left: 26px;
  padding-right: 26px;
}

/* Button Styles */
.roundedButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 10px;
  border-radius: 30px;
  font-family: Poppins-Semibold;
}

.buttonEnabled {
  background-color: var(--primary);
}

.buttonTextEnabled {
  color: var(--white);
}

.buttonDisabled {
  background-color: var(--lightGray);
}

.buttonTextDisabled {
  color: var(--white);
}

/* Presets */
.closed {
  text-transform: uppercase;
  color: black;
  font-family: Poppins-Semibold;
  font-size: 0.8rem;
}
.storeDetailContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.sectionHeaderBold {
  font-family: Poppins-Bold;
  font-size: 1rem;
  letter-spacing: 0.2px;
}

.sectionHeaderSemibold {
  font-family: Poppins-Semibold;
  font-size: 0.95rem;
}

.subTextGray {
  color: var(--gray);
  font-family: Poppins-Medium;
  font-size: 0.8rem;
}

.subTextGrayRegular {
  color: var(--gray);
  font-family: Poppins-Regular;
  font-size: 0.8rem;
}

.screenHeader {
  padding-top: 20px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.screenHeaderText {
  font-family: Lexend-Semibold;
  font-size: 1.6rem;
  margin-bottom: 10px;
}

.phoneContainer {
  transform: scaleX(-1);
  align-self: flex-start;
  width: 30px;
  height: 30px;
  margin-left: 10px;
}

.foodCount {
  width: 28px;
  margin-right: 5px;
  font-family: Poppins-Medium;
  font-size: 0.9rem;
}

.foodName {
  margin-top: 4px;
  font-family: Poppins-Semibold;
  align-self: flex-start;
  line-height: 18px;
}

.foodOptions {
  font-family: Poppins-Medium;
  font-size: 12px;
}

.foodRemark {
  color: var(--gray);
  font-family: Poppins-Medium;
  font-size: 14px;
  margin-top: 4px;
}

.price {
  margin-left: 50px;
  font-family: Poppins-Semibold;
  font-size: 0.9rem;
}

/* Input Fields */
input[type='text'].orangeBoxBorder,
input[type='email'].orangeBoxBorder,
input[type='tel'].orangeBoxBorder {
  border: 1px solid var(--primary);
  border-radius: 5px;
  background-color: transparent;
}

input[type='text'].grayBoxBorder,
input[type='email'].grayBoxBorder,
input[type='tel'].grayBoxBorder {
  border: 1px solid var(--gray);
  border-radius: 10px;
  background-color: transparent;
}

input[type='text'].grayUnderlineInput,
input[type='email'].grayUnderlineInput,
input[type='tel'].grayUnderlineInput {
  border: none;
  border-bottom: 1px solid var(--gray);
  background-color: transparent;
}

input[type='text'].orangeUnderlineInput,
input[type='email'].orangeUnderlineInput,
input[type='text'].orangeUnderlineInput {
  border: none;
  border-bottom: 1px solid var(--primary);
  background-color: transparent;
  font-size: 1.2rem;
}

input[type='text'].orangeUnderlineInput:focus {
  border: none;
  border-bottom: 2px solid var(--primary);
  background-color: transparent;
}

input[type='text'].smallText,
input[type='email'].smallText,
input[type='tel'].smallText {
  font-family: 'Poppins-Regular';
  font-size: 0.8rem;
}
